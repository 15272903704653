import React from "react"
import logos from "../assets/images/icons/projekt.png"
import styled from "styled-components"

const StyledLogos = styled.img`
  max-width: 100%;
  margin: 50px 0;
`
const StyledTextContainer = styled.div`
  padding: 0 50px;
`

const ChemicalsPage = () => (
  <div className="container mt16 mb16">
    <StyledLogos src={logos} alt="Logo projektu UE"/>
    <StyledTextContainer>
      <p>MY WASH SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ SPÓŁKA KOMANDYTOWA informuje, iż
        w dniu 29.06.2021 r. została podpisana umowa na dofinansowanie projektu pt. Usługa badawcza
        polegająca na opracowaniu innowacyjnej lancy używanej na myjniach samochodowych Poddziałanie
        2.3.2: Bon na innowacje dla MŚP, Program Operacyjny Inteligentny Rozwój współfinansowany ze
        środków Europejskiego Funduszu Rozwoju Regionalnego.</p>
      <p><strong>Cel projektu</strong>: zapewnienie przedsiębiorstwu dostępu do proinnowacyjnej usługi świadczonej przez
        jednostkę naukową, w wyniku której opracowany zostanie nowy produkt, jakim jest lanca zawierająca układ
        sterowania
        procesem mycia.</p>
      <p><strong>Efekty projektu:</strong> opracowanie lancy zawierającej układ sterowania procesem mycia w myjniach
        produkowanych przez MY WASH do programu pianowania i mycia zasadniczego stosowana będzie jedna lanca myjąca, a
        nie
        dwie, jak miało to miejsce dotychczas. Spowoduje to oszczędność czasu mycia (o ok. 10%), a także wpłynie na
        zmniejszenie zużycia wody i chemii (o ok. 5%) w porównaniu do standardowego procesu mycia dwoma lancami.<br/>
        Ponadto możliwość zastosowania na myjni jednej lancy zamiast dwóch przełoży się na szybszy proces wykonania
        wyrobu
        i montażu całej technologii u klienta
      </p>

      <p>
        <strong>Wartość projektu</strong>: 303 588,60 PLN<br/>
        <strong>Wkład Funduszy Europejskich</strong>: 209 797,00 PLN<br/>
        <strong>Umowa nr</strong>: POIR.02.03.02-20-0051/20-00<br/>
      </p>
    </StyledTextContainer>
  </div>
)

export default ChemicalsPage
